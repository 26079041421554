.floatBtnContent {
  text-align: center;
  z-index: 1000;
  width: calc(100% - 62px);
  height: 54px;
  bottom: 15px;
}

.content {
  color: var(--app-text-color);
  text-align: center;
  margin-bottom: 184px;
  padding: 46px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 28px;

  > .title {
    font-family: Inter, Inter;
    font-weight: bold;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 45px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  > .desc {
    margin-top: 24px;
    font-family: Inter, Inter;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }

  > .signUpBtn {
    font-family: Inter, Inter;
    display: inline-block;
    border-radius: 6px;
    margin-top: 42px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    padding: 15px 60px;

    background: linear-gradient( 270deg, #7141F9 0%, #1E85FF 100%);
    border-radius: 6px;
    cursor: pointer;
  }
}

@media screen and (min-width: 899px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 164px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 134px;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 577px) {
  .floatBtnContent {
    display: block;
  }
  .content {
    display: none;
    margin-bottom: 50px;
  }
}

